import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Trans } from "@lingui/macro";
import { styled, media, angle, pink } from "../../styles";
import { Page, Content, Link } from "../../components";
import { Stage } from "./components";
import RocketImg from "./assets/Rocket_ani.inline.svg";
import BlitzImg from "./assets/Blitz_ani.inline.svg";
import ExplosionImg from "./assets/Explosion_ani.inline.svg";

export const HomePage: React.FC<{}> = props => {
  const {
    paperImg: {
      image: {
        fixed: { src: paperImg }
      }
    },
    cactusImg: {
      image: {
        fixed: { src: cactusImg }
      }
    },
    pinImg: {
      image: {
        fixed: { src: pinImg }
      }
    },
    birdImg: {
      image: {
        fixed: { src: birdImg }
      }
    }
  }: {
    paperImg: {
      image: {
        fixed: { src: string };
      };
    };
    cactusImg: {
      image: {
        fixed: { src: string };
      };
    };
    pinImg: {
      image: {
        fixed: { src: string };
      };
    };
    birdImg: {
      image: {
        fixed: { src: string };
      };
    };
  } = useStaticQuery(graphql`
    query {
      paperImg: file(relativePath: { eq: "home/bg_paper.png" }) {
        image: childImageSharp {
          fixed(width: 1000, quality: 90) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      cactusImg: file(relativePath: { eq: "home/lego_kaktus.png" }) {
        image: childImageSharp {
          fixed(quality: 90) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      pinImg: file(relativePath: { eq: "home/pin.png" }) {
        image: childImageSharp {
          fixed(quality: 90) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      birdImg: file(relativePath: { eq: "home/pink_bird.png" }) {
        image: childImageSharp {
          fixed(quality: 90) {
            # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `);

  let returnFrom = "";

  if (typeof window === "object") {
    returnFrom = window.location.search.replace("?", "");
  }

  return (
    <Page
      title="About"
      seoTitle="Audio-Agentur | Musik, Sounddesign & Sprachaufnahmen | German Wahnsinn"
      description="Wir komponieren und vertonen für euch und für alle Werbeagenturen, Filmproduktionen, Verlage, Label und Musiker."
      keywords={[
        "German Wahnsinn",
        "Tonstudio",
        "Musikstudio",
        "Sounddesign",
        "Sprachaufnahmen",
        "Audio",
        "Postproduction",
        "Hörbücher",
        "Hörspiele",
        "Podcasts",
        "DOLBY ATMOS"
      ]}
      sideLabel={false}
      bgPaper="square"
      {...props}
    >
      <Stage returnFrom={returnFrom} />

      <Content>
        <HomeContainer id="about">
          <HomeHeading>
            <Trans>
              Wir sind <span>German Wahnsinn!</span>
            </Trans>
          </HomeHeading>

          <Explosion alt="Explosion" />

          <Paper bg={paperImg}>
            <Pin src={pinImg} alt="Pin" />

            <Blitz alt="Blitz" />

            <Bird src={birdImg} alt="Pink bird" />

            <HomeText>
              <Trans>
                Wir sind eine 360° Audio-Agentur mit Tonstudio-Komplex in Hamburg St. Pauli. Als Deutschlands kreativste Audio Agency begleiten wir als Sound- und Branding-Experten Marken und Agenturen durch alle Phasen der auditiven Markenführung und -kommunikation, von der Strategiefindung und Beratung über die Konzeption und Kreation bis hin zur Produktion.
              </Trans>
            </HomeText>

            <HomeText>
              <Trans>
                Wir produzieren{" "}
                <Link to="/work/">MUSIK, SOUNDDESIGN und SPRACHE</Link>.
                und das von mono bis DOLBY ATMOS.
              </Trans>
            </HomeText>

            <HomeText>
              <Trans>
                Wir{" "}
                <Link to="/work/composition/">KOMPONIEREN und VERTONEN</Link>{" "}
                für Werbeagenturen, Filmproduktionen, Verlage, Labels und
                Musiker. Wir machen{" "}
                <Link to="/work/sound-design/">AUDIO-POSTPRODUCTION</Link> für{" "}
                <Link to="/work/commercials/">COMMERCIALS</Link>.
              </Trans>
            </HomeText>

            <HomeText>
              <Trans>
                Wir inszenieren und veröffentlichen{" "}
                <Link to="/work/podcast/">PODCASTS,</Link>{" "}
                <Link to="/work/audiobooks/">HÖRSPIELE UND HÖRBÜCHER</Link>.
              </Trans>
            </HomeText>

            <HomeText>
              <Trans>
                Wir betreiben gemeinsam mit <Link to="https://corneliafunke.com/">Cornelia Funke</Link> den Verlag{" "}
                <Link to="https://www.atmende-buecher.de/">Atmende Bücher</Link>
                .
              </Trans>
            </HomeText>

            <HomeText>
              <Trans>
                Wir suchen neue Talente mit eigenen{" "}
                <Link to="https://germanwahnsinn.de/specials/german-wahnsinn-sprecher-gesucht">
                  SPRECHER-CASTINGS
                </Link>
                .
              </Trans>
            </HomeText>

            <HomeText>
              <Trans>
                Wir arbeiten mit <Link to="https://www.youtube.com/watch?v=Oj1ErBnjlJ8">BANDS</Link>{" "}
                und veröffentlichen Musik auf unserem Label{" "}
                <Link to="/music/">GERMAN WAHNSINN RECORDS</Link>.
              </Trans>
            </HomeText>

            <HomeText>
              <Trans>
                Wir entwickeln <Link to="/specials/">AUDIOKONZEPTE (CORPORATE AUDIOBRANDINGS)</Link> für
                Unternehmen, klären und lizenzieren Musikrechte.
              </Trans>
            </HomeText>

            <HomeText>
              <Trans>
                Wir schaffen immersive Erlebnisse im Bereich XR, VR, AR, dem Metaverse und setzen
                mit dynamischen Storytelling und dem Einsatz{" "}
                <Link to="/work/arvrtechnology/">NEUSTER TECHNOLOGIEN</Link>, darunter KI Stimmen, Game Audio und Tools{" "}
                für neuartige Audio Kreationen.
              </Trans>
            </HomeText>

            <HomeText>
              <Trans>
                Wir arbeiten auf 500 Quadratmetern in unserem Tonstudio-Komplex
                mit <Link to="/studio/">7 REGIE- UND 3 AUFNAHMERÄUMEN</Link> in
                Hamburg auf St. Pauli.
              </Trans>
            </HomeText>
          </Paper>
        </HomeContainer>

        <Rocket alt="Rocket" />

        <Cactus alt="Cactus" src={cactusImg} />
      </Content>
    </Page>
  );
};

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;

  a {
    @media not all and (hover: hover) {
      color: ${pink};

      &:hover {
        /* animation: none; */
      }
    }

    @media not all and (hover: none) {
      color: inherit;

      &:hover {
        color: ${pink};
      }
    }
  }

  ${media.laptop} {
    width: 83.3%;
  }
`;

const Paper = styled.div<{ bg: string }>`
  background-image: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  z-index: 1;

  padding: 0;
  margin: 0 auto;

  ${media.tablet} {
    background-image: ${({ bg }) => `url(${bg})`};
    padding: 10% 18% 20rem 5rem;
    margin: 2rem 0 auto auto;
    ${angle(-2)};
  }

  ${media.desktop} {
    margin: 5rem 0 auto auto;
    padding: 10% 18% 20rem 10%;
  }
`;

const HomeText = styled.p`
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0.01em;

  ${media.tablet} {
    font-size: 22px;
    line-height: 27px;
  }

  ${media.laptop} {
    font-size: 30px;
    line-height: 36px;
  }
`;

const HomeHeading = styled.h1`
  z-index: 1;
  margin-bottom: 2rem;
  margin-left: 0;
  font-size: 37px;

  span::before {
    content: "\\A";
    white-space: pre;
  }

  ${media.tablet} {
    margin-bottom: 0;
    margin-left: 3.5rem;
    font-size: 67px;
    ${angle(-4)};
  }

  ${media.laptop} {
    font-size: 72px;
    margin-left: 2.5rem;
  }

  ${media.desktop} {
    margin-left: 0;
  }
`;

const Explosion = styled(ExplosionImg)`
  position: absolute;
  top: -1%;
  right: 5%;
  width: 40%;
  max-width: 150px;
  z-index: 0;

  ${media.tablet} {
    max-width: unset;
    width: 30%;
    top: -5%;
    right: 15%;
  }

  ${media.laptop} {
    width: 20%;
    top: -1%;
  }
`;

const Blitz = styled(BlitzImg)`
  position: absolute;
  display: none;
  z-index: 2;

  ${media.laptop} {
    display: block;
    top: 3%;
    right: 13%;
    width: 60px;
    ${angle(16)};
  }
`;

const Rocket = styled(RocketImg)`
  position: relative;
  z-index: 2;
  width: 50%;
  max-width: 200px;
  margin: 0 25%;

  ${media.tablet} {
    display: block;
    width: 30%;
    max-width: unset;
    position: absolute;
    bottom: 5%;
    left: 15%;
    margin: unset;
  }

  ${media.laptop} {
    width: 20%;
    bottom: 3%;
  }
`;

const Pin = styled.img`
  position: absolute;
  display: none;
  z-index: 2;
  ${angle(-15)};

  ${media.laptop} {
    display: block;
    width: 25%;
    top: 25%;
    right: -2%;
  }

  ${media.desktop} {
    width: 20%;
    right: 2%;
  }
`;

const Bird = styled.img`
  display: none;

  ${media.laptop} {
    display: block;
    position: absolute;
    top: 35%;
    left: -25%;
    width: 238px;
    z-index: 2;
    ${angle(22)};
  }
`;

const Cactus = styled.img`
  position: absolute;
  display: none;
  z-index: 2;
  left: 48%;

  ${media.tablet} {
    display: block;
    width: 30%;
    bottom: 5%;
  }

  ${media.laptop} {
    width: 25%;
    bottom: -6%;
  }

  ${media.desktop} {
    width: 20%;
  }
`;
