import React, { useContext, useState, useEffect } from "react";
import { styled, media } from "../../../styles";
import stageImg from "../assets/header_bg.svg";
import logoImg from "../assets/gw_logo.svg";
import logoQuerImg from "../assets/gw_logo-quer.svg";
import { SkullContext } from "../../../components/Skull";

export interface StageProps {
  returnFrom?: string;
}

export const Stage: React.FC<StageProps> = ({ returnFrom }) => {
  const [enterFrom, setEnterFrom, isSkullVisible, setSkullVisible] = useContext(
    SkullContext
  );

  setEnterFrom(returnFrom);

  useEffect(() => {
    setSkullVisible(true);

    return () => {
      setSkullVisible(false);
    };
  }, []);

  return <StageContainer />;
};

const StageContainer = styled.header`
  user-select: none;

  background-image: url(${stageImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin-bottom: 4rem;
  min-height: 100vh;

  overflow: hidden;

  canvas {
    position: relative;
    top: -15%;
  }

  ${media.tablet} {
    canvas {
      position: relative;
      top: 0;
    }
  }

  &:before {
    content: "";

    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: 65% 65%;

    background-image: url(${logoQuerImg});

    ${media.landscape} {
      background-image: url(${logoImg});
    }

    ${media.tablet} {
      background-size: 85% 85%;
      background-image: url(${logoImg});
    }
  }
`;
